import * as React from "react";
import styled from "styled-components";

import {
  Maybe,
  SanityAllergen,
  SanityProductBadge,
  SanityProductModule,
  SanityRecipeInfo,
} from "@graphql-types";
import { assets, TABLET_BREAKPOINT } from "@util/constants";
import { Container, P } from "@util/standard";
import { useStore } from "@state/store";
import { handleSaveRecipeProduct } from "@util/helper";
import { useCheckScreenWidth } from "@util/hooks";
import { isSanityAllergen, isSanityProductBadge } from "@util/types";

interface Props {
  recipeInfo?: SanityRecipeInfo;
  productInfo?: SanityProductModule;
  id?: Maybe<string>;
  isBlog?: boolean;
  isOutOfStock?: boolean;
  isFav?: boolean;
}

const FavoriteContainer = styled(Container)`
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.8;
  }
  pointer-events: initial;
`;

const StyledContainer = styled(Container)`
  pointer-events: none;
`;

const AllergenContainer = styled(Container)<{
  isNew?: boolean;
  isOutOfStock?: boolean;
}>`
  width: ${({ isNew, isOutOfStock }) =>
    isOutOfStock === true ? `120px` : isNew === true ? `68px` : `fit-content`};
  height: 38px;
  border-radius: 0 18px 18px 0;
  align-items: center;
  justify-content: center;
  margin: 8px 0 0 0;
  min-width: 68px;
  img {
    margin: -2px 0 -2px -2px;
    padding: 0px 0px 0px 10px;
    width: 23px;
    height: auto;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: ${({ isNew, isOutOfStock }) =>
      isOutOfStock === true ? `90px` : isNew === true ? `50px` : `fit-content`};
    height: 20px;
    margin: 8px 0 0 0;
    min-width: 50px;
    img {
      padding: 0px 0px 0px 5px;
      width: 15px;
    }
    p {
      max-width: 69px;
      white-space: initial;
      line-height: 0.9;
    }
  }
`;

interface AllergenProps {
  badge: SanityAllergen | SanityProductBadge;
}

const Allergen = ({ badge }: AllergenProps) => {
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();
  const isTabletAndBelow = (isMobileWidth || isTabletWidth) as boolean;

  return (
    <AllergenContainer
      backgroundColor={
        isSanityProductBadge(badge) ? badge.color ?? "merlin" : "white"
      }
    >
      {badge.icon && (
        <img
          src={badge.icon?.asset?.url as string}
          alt={badge.icon?.altText ?? badge.title ?? "icon-button"}
        />
      )}
      <P
        bold
        color={
          isSanityProductBadge(badge)
            ? badge.color === "white" || badge.color === "background"
              ? "merlin"
              : "white"
            : "merlin"
        }
        fontSize={16}
        fontWeight="400"
        tabletFontSize={10}
        margin={isTabletAndBelow ? "0 9px 0 5px" : "0px 13px 0px 9px"}
      >
        {badge.title}
      </P>
    </AllergenContainer>
  );
};

const PreviewAllergens = (props: Props) => {
  const { recipeInfo, productInfo, id, isBlog, isFav } = props;
  const { savedRecipes, savedProducts, savedBlogs } = useStore();
  const { isTabletWidth, isMobileWidth } = useCheckScreenWidth();

  let info: SanityRecipeInfo | SanityProductModule | null = null;
  if (recipeInfo) {
    info = recipeInfo;
  } else if (productInfo) {
    info = productInfo;
  } else if (!isBlog === true) {
    return null;
  }
  if (info == null) return null;

  const outOfStock = props.isOutOfStock;

  const isTabletAndBelow = (isMobileWidth || isTabletWidth) as boolean;

  const type = isBlog === true ? "blog" : productInfo ? "product" : "recipe";
  const { featuredAllergen, featuredBadge } = info;

  return (
    <StyledContainer
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent={
        info?.newProduct ||
        featuredBadge?.title ||
        featuredAllergen?.title ||
        outOfStock === true
          ? "space-between"
          : "flex-end"
      }
    >
      {(info?.newProduct ||
        featuredBadge?.title ||
        featuredAllergen?.title ||
        outOfStock === true) && (
        <Container display="flex" flexDirection="column">
          {outOfStock === true && (
            <AllergenContainer backgroundColor="merlin" isOutOfStock={true}>
              <P bold color="white" fontSize={16} tabletFontSize={10}>
                Sold out
              </P>
            </AllergenContainer>
          )}

          {info?.newProduct && (
            <AllergenContainer backgroundColor="merlin" isNew={true}>
              <P color="white" fontSize={16} tabletFontSize={10}>
                New
              </P>
            </AllergenContainer>
          )}

          {!info?.newProduct &&
            outOfStock !== true &&
            featuredBadge &&
            isSanityProductBadge(featuredBadge) &&
            featuredBadge.title && <Allergen badge={featuredBadge} />}

          {featuredAllergen && featuredAllergen?.title && (
            <Allergen badge={featuredAllergen} />
          )}

          {!info?.newProduct &&
            outOfStock !== true &&
            featuredBadge &&
            isSanityAllergen(featuredBadge) &&
            featuredBadge.title && <Allergen badge={featuredBadge} />}
        </Container>
      )}

      <FavoriteContainer
        backgroundColor="merlin"
        width="38px"
        height="36px"
        tabletWidth="27px"
        tabletHeight="27px"
        borderRadius={18}
        display="flex"
        alignItems="center"
        justifyContent="center"
        margin="10px 10px 0px 0px"
        tabletMargin="10px 9px 0px 0px"
        cursor="pointer"
        onClick={() => handleSaveRecipeProduct(id, type)}
        zIndex={2}
      >
        <img
          src={
            savedRecipes?.includes(id!) ||
            savedProducts?.includes(id!) ||
            savedBlogs?.includes(id!) ||
            isFav
              ? assets.favouritedIcon
              : assets.favouriteIcon
          }
          alt="Add to Favorite"
          width={isTabletAndBelow ? "13px" : "17px"}
          height={isTabletAndBelow ? "13px" : "17px"}
        />
      </FavoriteContainer>
    </StyledContainer>
  );
};

export default PreviewAllergens;
