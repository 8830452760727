import { Maybe, SanityBlog, SanityRecipe } from "@graphql-types";
import RecipePreview from "@shared/recipePreview";
import { TABLET_BREAKPOINT, colorsRGB, assets } from "@util/constants";
import { Container, H2 } from "@util/standard";
import React, {
  createRef,
  RefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import TextButton from "@shared/textButton";
import BlogPreview from "@components/blogs/blogPreview";

interface Props {
  recipes?: Maybe<SanityRecipe>[];
  category?: any;
  hideArrows?: boolean;
  width?: string;
  showTabletNav?: boolean;
  blogs?: Maybe<SanityBlog>[];
  scalableWidths?: boolean;
}

export const ScrollableContainer = styled.div<{
  dataLength?: number;
}>`
  display: flex;
  flex-wrap: nowrap;
  overflow: auto hidden;
  white-space: nowrap;
  height: 100%;
  grid-template-columns: repeat(${props => props.dataLength ?? 4}, 25%);
  grid-auto-flow: row;
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const RightIcon = styled.img``;

const LeftIcon = styled(RightIcon)`
  transform: rotate(180deg);
`;

const ButtonContainer = styled.div<{
  reverseDirection?: boolean;
  hide?: boolean;
  showTablet?: boolean;
}>`
  position: absolute;
  height: 46px;
  width: 46px;
  background-color: ${colorsRGB.white(0.8)};
  border-radius: 100%;
  ${props => (props.reverseDirection ? "left: 10px" : "right: 10px")};
  top: 150px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  ${props => (props.hide ? "display: none" : "display: flex")};

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    height: 25px;
    width: 25px;
    top: 178px;
    ${props => (props.reverseDirection ? "left: -12px;" : "right: -12px;")};
    ${props =>
    props.showTablet !== true &&
    `
      display: none;
    `};
  }
`;

const RecipePreviewCarousel = (props: Props) => {
  const { recipes, category, hideArrows, width, showTabletNav, blogs } = props;

  const scrollableRef = createRef<HTMLDivElement>();
  const handleHorizontalScroll = (goBack?: boolean) => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: 0,
        left: goBack
          ? Math.max(
            scrollableRef.current.scrollLeft -
            scrollableRef.current.clientWidth
          )
          : Math.max(
            scrollableRef.current.scrollLeft +
            scrollableRef.current.clientWidth
          ),
        behavior: "smooth",
      });
    }
  };
  const [showArrow, setShowArrow] = useState(false);
  const elScrollChildLength = (recipes && recipes?.length) ?? 0;
  const elScrollChild = useRef<RefObject<HTMLDivElement>[]>([]);

  if (elScrollChild.current.length !== elScrollChildLength) {
    elScrollChild.current = Array(elScrollChildLength)
      .fill(null)
      .map((_, i) => elScrollChild.current[i] || createRef());
  }

  useEffect(() => {
    const checkSliderArrowDisplay = () => {
      let sliderContentWidth = 0;
      const sliderWidth = scrollableRef?.current?.offsetWidth ?? 0;

      elScrollChild.current.forEach(el => {
        if (!el) return;

        sliderContentWidth += el.current?.offsetWidth ?? 0;
      });
      if (sliderWidth >= sliderContentWidth) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };
    checkSliderArrowDisplay();
    window.addEventListener("resize", checkSliderArrowDisplay);

    return () => {
      window.removeEventListener("resize", checkSliderArrowDisplay);
    };
  }, []);

  if (!recipes && !blogs) {
    return null;
  }

  return (
    <Container
      flexDirection="column"
      margin={category ? "0px 0px 90px 70px" : "100px 0px 100px 150px"}
      mobileMargin="30px 0px 30px 30px"
      position="relative"
      width={width}
    >
      <Container margin="0 0 1em" alignItems="flex-end">
        <H2 margin="0 .5em 0 0">
          {category ? category.name : blogs ? "Related Posts" : "Featured Recipes"}
        </H2>
        {recipes && (
          <TextButton
            linkTo={category ? `/recipes/${category.slug}` : `/recipes`}
          />
        )}
        {blogs && category !== false && (
          <TextButton linkTo={category ? `/blog/${category.slug}` : `/blogs`} />
        )}
      </Container>

      <ScrollableContainer ref={scrollableRef} dataLength={recipes?.length}>
        {recipes &&
          recipes.length > 0 &&
          recipes.map((recipe, i) => {
            if (!recipe) return;
            return (
              <Container
                key={`${recipe.info?.slug?.current}`}
                ref={elScrollChild.current[i]}
              >
                <RecipePreview recipe={recipe} isCategoryResult />
              </Container>
            );
          })}
        {blogs &&
          blogs.length > 0 &&
          blogs.map((blog, i) => {
            if (!blog) return;
            return (
              <Container
                key={`${blog.slug?.current}`}
                ref={elScrollChild.current[i]}
              >
                <BlogPreview blog={blog} isCategoryResult />
              </Container>
            );
          })}
      </ScrollableContainer>
      {!hideArrows && (
        <>
          {/* Hide buttons when content is smaller than container */}
          {showArrow && (
            <>
              <ButtonContainer
                reverseDirection
                onClick={() => handleHorizontalScroll(true)}
                showTablet={showTabletNav}
              >
                <LeftIcon
                  src={assets.arrowRight}
                  alt="left-arrow"
                  height="20px"
                  width="10px"
                />
              </ButtonContainer>
              <ButtonContainer
                onClick={() => handleHorizontalScroll()}
                showTablet={showTabletNav}
              >
                <RightIcon
                  src={assets.arrowRight}
                  alt="right-arrow"
                  height="20px"
                  width="10px"
                />
              </ButtonContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default RecipePreviewCarousel;
