import * as React from "react";
import styled from "styled-components";

import {
  Maybe,
  SanityImageTextBlock,
  SanityFullWidthContent,
  SanityFullWidthContentCarouselOrFullWidthTextBlockOrImageTextBlock,
} from "@graphql-types";
import {
  colors,
  assets,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from "@util/constants";
import { Container, P } from "@util/standard";
import {
  isSanityFullWidthContent,
  isSanityFullWidthContentCarousel,
  isSanityFullWidthTextBlock,
  isSanityImageTextBlock,
  isSanityGallery,
  isSanityImageWithMeta,
  isSanityHtml,
  isSanityVideo,
} from "@util/types";
import { BlocksContent, Carousel, Html, Image, Video } from "@global";
import CarouselPreview from "./carouselPreview";
import GalleryCarousel from "@shared/galleryCarousel";
import { GiveMeSomeSpace } from "@util/standard";
import NewsletterCTA from "@shared/newsletterBlock";

const Wrapper = styled(Container)`
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
`;

const ContentContainer = styled(Container)<{
  background: Maybe<boolean> | undefined;
}>`
  width: 100%;
  margin: 0 auto;
  flex-direction: row;
`;

const ImageContainer = styled(Container)<{
  reverse: Maybe<boolean> | undefined;
}>`
  width: ${props => (props.reverse ? "51%" : "50%;")};
  max-width: 700px;
  // margin: ${props => (props.reverse ? "5% 0 5% auto;" : "5% auto 5% 0;")};
  height: 100%;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: 100%;
    margin: 10% auto;
  }

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 15% auto;
  }
`;

const BlocksContentContainer = styled(Container)<{
  reverse: Maybe<boolean> | undefined;
}>`
  width: ${props => (props.reverse ? "45%" : "40%;")};
  margin: ${props => (props.reverse ? "auto auto auto 0px;" : "auto;")};
  p {
    width: 90%;
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 40%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    margin: -9% auto 15% auto;
    p {
      width: 100%;
    }
  }
`;

const BlocksContentContainerFullWidth = styled(Container)<{
  applyBackground?: Maybe<boolean> | undefined;
}>`
  ${props =>
    props.applyBackground !== false
      ? `
  background-size: 700px;
  `
      : `
  background-color: ${colors.white};
  `};
  width: 100%;
  h2,
  h3,
  h1,
  h5,
  h4,
  ul,
  ol,
  p {
    width: 65%;
    margin: auto;
    margin-bottom: 24px;
  }
  h2,
  h4,
  ul {
    margin-bottom: 1%;
  }

  .link-wrapper {
    width: 65%;
    margin: auto;
  }
`;

const CarouselContainer = styled(Container)`
  width: 100%;
  margin: auto;
  .slick-prev {
    left: 25px;
    z-index: 2;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: 0px;
    }
  }
  .slick-next {
    right: 30px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      right: 0px;
    }
  }
  .slick-prev,
  .slick-next {
    width: 52px;
    height: 52px;
  }
  .slick-dots {
    bottom: 100px !important;
    left: -21.4%;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      left: -27.4%;
    }
  }
  .slick-dots li.slick-active button:before {
    color: white;
  }
  .slick-dots li button:before {
    color: white;
    font-size: 9px;
  }
  .slick-dots li {
    margin: 0;
  }
  .slick-prev:before,
  .slick-next:before {
    opacity: 1;
    font-size: 52px;
    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      font-size: 30px;
    }
  }
  .slick-slider {
    width: 100%;
  }
`;

const DirectionContainer = styled(Container)<{
  reverse: Maybe<boolean> | undefined;
}>`
  padding: 55px 0;
  width: 85%;
  margin: auto;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  align-items: center;
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    padding: 0;
    flex-direction: column;
  }
`;

interface Props {
  data?:
    | Maybe<Maybe<SanityImageTextBlock>[]>
    | Maybe<
        Maybe<SanityFullWidthContentCarouselOrFullWidthTextBlockOrImageTextBlock>[]
      >
    | undefined;
  singleBlockData?:
    | Maybe<SanityImageTextBlock>
    | Maybe<SanityFullWidthContent>
    | undefined;
  customHeight?: string;
  customMobileHeight?: string;
  containerWidth?: string;
  hasNewsletter?: boolean;
  insertNode?: {
    position: number;
    node: JSX.Element;
  };
}

const ImageTextBlockWithCarousel = ({
  data,
  singleBlockData,
  customHeight,
  customMobileHeight,
  hasNewsletter = false,
  insertNode,
}: Props) => {
  if (singleBlockData) {
    data = [singleBlockData];
  }

  if (data == null) {
    return null;
  }

  return (
    <Wrapper>
      {data.map((data, i) => {
        if (data == null) {
          return null;
        }
        if (isSanityHtml(data)) {
          return <Html content={data} key={data?._key} />;
        }
        if (isSanityVideo(data)) {
          return <Video videoSrcURL={data.url} />;
        }

        if (isSanityGallery(data)) {
          return (
            <React.Fragment key={`isSanityGallery-${i}`}>
              {insertNode &&
                insertNode.position === i &&
                insertNode.node != null &&
                insertNode.node}

              {hasNewsletter && (
                <>
                  <NewsletterCTA />
                  <GiveMeSomeSpace space={40} />
                </>
              )}

              <GalleryCarousel content={data} />
            </React.Fragment>
          );
        }
        if (isSanityImageTextBlock(data)) {
          return (
            <React.Fragment key={data._key}>
              {insertNode &&
                insertNode.position === i &&
                insertNode.node != null &&
                insertNode.node}

              <ContentContainer
                backgroundRGBColor={data?.backgroundColor?.value}
              >
                <DirectionContainer reverse={data.reverse}>
                  <ImageContainer reverse={data.reverse}>
                    <Image
                      objectFit="cover"
                      width="auto"
                      height="auto"
                      imageData={data?.image?.asset?.gatsbyImageData}
                    />
                  </ImageContainer>
                  <BlocksContentContainer reverse={data.reverse}>
                    <BlocksContent blocks={data.text?._rawBlocks} />
                  </BlocksContentContainer>
                </DirectionContainer>
              </ContentContainer>
            </React.Fragment>
          );
        }
        if (isSanityFullWidthContentCarousel(data)) {
          return (
            <React.Fragment key={`isSanityFullWidthContentCarousel-${i}`}>
              {insertNode &&
                insertNode.position === i &&
                insertNode.node != null &&
                insertNode.node}

              <CarouselContainer>
                <Carousel iconButton>
                  {data.backgroundMedia?.map(content => {
                    if (content == null) {
                      return <P color="errorRed">Error loading content</P>;
                    }
                    return (
                      <CarouselPreview
                        customHeight={customHeight}
                        key={content?._key}
                        content={content}
                      />
                    );
                  })}
                </Carousel>
              </CarouselContainer>
            </React.Fragment>
          );
        }
        if (isSanityFullWidthContent(data)) {
          return (
            <React.Fragment key={`isSanityFullWidthContent-${i}`}>
              {insertNode &&
                insertNode.position === i &&
                insertNode.node != null &&
                insertNode.node}

              <CarouselPreview
                customHeight={customHeight}
                customMobileHeight={customMobileHeight}
                content={data}
                customCardColour={data?.cardColour?.value}
              />
            </React.Fragment>
          );
        }
        if (isSanityFullWidthTextBlock(data)) {
          return (
            <React.Fragment key={`isSanityFullWidthTextBlock-${i}`}>
              {insertNode &&
                insertNode.position === i &&
                insertNode.node != null &&
                insertNode.node}

              <BlocksContentContainerFullWidth
                applyBackground={data?.applyBackground}
              >
                <Container margin="70px auto 30px">
                  <BlocksContent blocks={data?.textBlock?._rawBlocks} />
                </Container>
              </BlocksContentContainerFullWidth>
            </React.Fragment>
          );
        }
        if (isSanityImageWithMeta(data)) {
          return (
            <Container
              margin="2% auto 2% auto"
              height="100%"
              width="45%"
              key={data?._key}
            >
              <Image imageData={data?.asset?.gatsbyImageData} />
            </Container>
          );
        }
        return null;
      })}
    </Wrapper>
  );
};

export default ImageTextBlockWithCarousel;
